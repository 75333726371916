import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './styles.css';

const EmptyList = () => (
  <>
    <div>
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto', justifyContent:'center'}}>
          <Skeleton customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)" count={1} width={150} height={15} />
        </div>
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto', justifyContent:'center'}}>
          <Skeleton customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)" count={2} width={600}/>
        </div>
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto', justifyContent:'center'}}>
          <Skeleton count={1} width={150} height={30} customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)" />
        </div>
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto',paddingTop:'20px'}}>
          <Skeleton count={1} width={800} height={300} customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)" />
        </div>
        
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto', justifyContent:'center',paddingTop:'30px'}}>
          <Skeleton count={2} width={600} customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)" />
        </div>
        <div className='blogList-wrap' style={{gridTemplateColumns: 'auto', justifyContent:'center',paddingTop:'20px'}}>
          <Skeleton count={1} height={100} width={600} customHighlightBackground="linear-gradient(90deg, rgba(0,0,0,0.09007352941176472) 0%, rgba(238,238,238,1) 64%, rgba(255,255,255,1) 100%)"/>
        </div>
    </div>
    {/* {Array(4)
      .fill()
      .map((_, index) => (
        <div>
          <div className='blogList-wrap'>
            <Skeleton count={2} />
          </div>
          <div className='blogList-wrap'>
            <div className="blogItem-wrap-skeleten">
              <div className="circle-skeletons">
                <Skeleton circle={true} height={30} width={30} count={1} />
                <Skeleton circle={true} height={30} width={30} count={1} />
                <Skeleton circle={true} height={30} width={30} count={1} />
              </div>
            </div>
          </div>
          <div className='blogList-wrap'>
            <div className="blogItem-wrap-skeleten" style={{ marginTop: '30px' }}>
              <div className="circle-skeletons">
                <Skeleton circle={true} height={50} width={50} count={1} />
                <Skeleton count={2} width={150} height={15} />
              </div>
            </div>
          </div>
        </div>
      ))} */}
  </>
);

export default EmptyList;
