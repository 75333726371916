import React, { useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import Rope2 from '../components/Rope/rope2'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import "./blog.css"
import Wall from '../components/WallGrid/Wall';
import { Helmet } from "react-helmet";

const blog = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImage(`/playground_assets/ourworks/${index}.png`);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };
  const a1 = [
    '/playground_assets/Blog/T-Rex-Dino-Endless-run.png',
    '/playground_assets/Blog/banners05.png',
    '/playground_assets/Blog/banners06.png',
  ];
  const a2 = [
    '/playground_assets/Blog/banners07.png',
    '/playground_assets/Blog/banners08.png',
    '/playground_assets/Blog/banners09.png',
  ];
  const a3 = [
    '/playground_assets/Blog/banners10.png',
    '/playground_assets/Blog/banners11.png',
    '/playground_assets/Blog/banners12.png',
  ];

  // const a5 = [
  //   '/playground_assets/Blog/banners13.png',
  //   '/playground_assets/Blog/banners14.png',
  //   '/playground_assets/Blog/banners15.png',
  // ];

  const a4 = [
    '/playground_assets/Blog/Bubble-Shooter-Pet.png',
    '/playground_assets/Blog/Girls-Coloring-Book_Color-Game.png',
    '/playground_assets/Blog/Bubble_Game.png',
  ];

  const a5 = [
    '/playground_assets/Blog/word-Castle-Game_1.png',
    '/playground_assets/Blog/Indic-Roots.png',
    '/playground_assets/Blog/Word-Wrap.png',
  ];

  const b1 = [
    'https://play.google.com/store/apps/details?id=com.KCCreations.DinoJumpingRun&hl=en_IN',
    'https://play.google.com/store/apps/details?id=com.funqtr.mosquitowar&hl=en_IN',
    'https://play.google.com/store/apps/details?id=com.eggies.mathgame',
  ]

  const b2 = [
    'https://play.google.com/store/apps/details?id=com.studio7775.BKBall&hl=en',
    'https://play.google.com/store/apps/details?id=com.ortsoftware.crosscrazeFree',
    'https://play.google.com/store/apps/details?id=com.crikey.colordash&hl=en_IN',
  ]

  const b3 = [
    'https://play.google.com/store/apps/details?id=com.jottit.jottitDown',
    'https://play.google.com/store/apps/details?id=com.moremountains.skytheory',
    'https://apps.apple.com/us/app/gonotes-grandstaff/id1587652827',
  ]

  const b4 = [
    'https://play.google.com/store/apps/details?id=com.bubble.shooter.pet.games&hl=en',
    'https://play.google.com/store/apps/details?id=color.number.coloring.paint.book&hl=en',
    'https://play.google.com/store/apps/details?id=com.bubblerainbow.game',
  ]

  const b5 = [
    'https://play.google.com/store/apps/details?id=com.wordcastle.game',
    'https://play.google.com/store/apps/details?id=jykra.indicroots&pcampaignid=web_share',
    'https://play.google.com/store/apps/details?id=com.BazanEnterprises.WordWrap',
  ]

  const c1 = [
    'T-Rex : Dino Endless run',
    'Mosquito War',
    'Math Game 2019',
  ]

  const c2 = [
    'Knife Battle',
    'CrossCraze Word Game',
    'Color Dash',
  ]

  const c3 = [
    'Jottit Down',
    'Sky Theory',
    'GoNotes GrandStaf',
  ]

  const c4 = [
    'Bubble Shooter Pet',
    'Girls Coloring Book',
    'Bubble 2 Rainbow',
  ]

  const c5 = [
    'Word Castle',
    'Indic Roots',
    'Word Wrap',
  ]

  return (
    <>
      <Helmet>
        <title>
          Our Products - Innovative Gaming Solutions | WM Gaming Studioz
        </title>
        <meta
          name="keywords"
          content="Game Development Projects, Game Development Portfolio, Gaming Studio Projects, Game Design Portfolio, Innovative Gaming Solutions, Mobile Game Development, Game Development Company Projects, Video Game Development Projects, Game Development Expertise, Top Gaming Projects, Interactive Game Design, Immersive Gaming Experiences, 3D Game Development, Game Development Showcase, Gaming Technology Projects, Game Creation Studio"
        />
        <meta
          name="description"
          content="Check out our portfolio of successful game development projects, from concept to execution. See how we transform ideas into engaging, high-quality games."
        />
        <meta name="og:site_name" content="Webmobril Gapngming Studioz" />
        <meta name="og:region" content="IN" />
        <meta name="og:country-name" content="INDIA" />
        <meta name="language" content="english" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://wmgamingstudioz.com/ourwork",
        "name": "Our Work - Webmobril Gaming Studioz",
        "description": "Explore Webmobril Gaming Studioz' completed projects, showcasing our expertise in game development and design.",
        "mainEntityOfPage": "https://wmgamingstudioz.com/ourwork",
        "publisher": {
          "@type": "Organization",
          "name": "Webmobril Gaming Studioz",
          "logo": {
            "@type": "ImageObject",
            "url": "https://wmgamingstudioz.com/playground_assets/edited_logo_with_text_509_x_144.png"
          }
        },
        "image": "https://www.wmgamingstudioz.com/assets/images/portfolio-cover.jpg",
        "keywords": "Game Development, Portfolio, Game Projects, Our Work, Game Studio"
      }
    `}
        </script>
      </Helmet>
      <Header rootClassName="header1-root-class-name" />
      <div className='showcase'>
        <div className='curtain'>
          <Rope2 i1={a1[0]} i2={a1[1]} i3={a1[2]} l1={b1[0]} l2={b1[1]} l3={b1[2]} a1={c1[0]} a2={c1[1]} a3={c1[2]} />
          <Rope2 i1={a2[0]} i2={a2[1]} i3={a2[2]} l1={b2[0]} l2={b2[1]} l3={b2[2]} a1={c2[0]} a2={c2[1]} a3={c2[2]} />
          <Rope2 i1={a3[0]} i2={a3[1]} i3={a3[2]} l1={b3[0]} l2={b3[1]} l3={b3[2]} a1={c3[0]} a2={c3[1]} a3={c3[2]} />
          <Rope2 i1={a4[0]} i2={a4[1]} i3={a4[2]} l1={b4[0]} l2={b4[1]} l3={b4[2]} a1={c4[0]} a2={c4[1]} a3={c4[2]} />
          <Rope2 i1={a5[0]} i2={a5[1]} i3={a5[2]} l1={b5[0]} l2={b5[1]} l3={b5[2]} a1={c5[0]} a2={c5[1]} a3={c5[2]} />
          {/* <Rope2 i1={a6[0]} i2={a6[1]} i3={a6[2]} l1={b6[0]} l2={b6[1]} l3={b6[2]} a1={c6[0]} a2={c6[1]} a3 ={c6[2]}/>
          <Rope2 i1={a5[0]} i2={a5[1]} i3={a5[2]} l1={b5[0]} l2={b5[1]} l3={b5[2]}/> */}
        </div>
      </div>
      <section className="showcaseart">
        <div className="about-us-container37">
          {/* {Array(28)
            .fill()
            .map((_, index) => (
              <div className="about-us-container54" key={index}>
                <div
                  className="ourwork"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={`/playground_assets/ourworks/${index + 1}.png`}
                    alt={`Image ${index + 1}`}
                  />
                </div>
              </div>
            ))} */}
          <div class="gallery">
            <img src="/playground_assets/ourworks/1.png" alt="Image 1" onClick={() => handleImageClick(1)} />
            <img src="/playground_assets/ourworks/2.png" alt="Image 2" onClick={() => handleImageClick(2)} />
            <img src="/playground_assets/ourworks/3.png" alt="Image 3" onClick={() => handleImageClick(3)} />
            <img src="/playground_assets/ourworks/4.png" alt="Image 4" onClick={() => handleImageClick(4)} />
            <img src="/playground_assets/ourworks/5.png" alt="Image 1" onClick={() => handleImageClick(5)} />
            <img src="/playground_assets/ourworks/6.png" alt="Image 2" onClick={() => handleImageClick(6)} />
            <img src="/playground_assets/ourworks/7.png" alt="Image 3" onClick={() => handleImageClick(7)} />
            <img src="/playground_assets/ourworks/8.png" alt="Image 4" onClick={() => handleImageClick(8)} />
            <img src="/playground_assets/ourworks/19.png" alt="Image 7" onClick={() => handleImageClick(19)} />
            <img src="/playground_assets/ourworks/9.png" alt="Image 1" onClick={() => handleImageClick(9)} />
            <img src="/playground_assets/ourworks/10.png" alt="Image 2" onClick={() => handleImageClick(19)} />
            <img src="/playground_assets/ourworks/11.png" alt="Image 3" onClick={() => handleImageClick(11)} />
            <img src="/playground_assets/ourworks/12.png" alt="Image 4" onClick={() => handleImageClick(12)} />
            <img src="/playground_assets/ourworks/13.png" alt="Image 1" onClick={() => handleImageClick(13)} />
            <img src="/playground_assets/ourworks/14.png" alt="Image 2" onClick={() => handleImageClick(14)} />
            <img src="/playground_assets/ourworks/15.png" alt="Image 3" onClick={() => handleImageClick(15)} />
            <img src="/playground_assets/ourworks/20.png" alt="Image 8" onClick={() => handleImageClick(20)} />
            <img src="/playground_assets/ourworks/16.png" alt="Image 4" onClick={() => handleImageClick(16)} />
            <img src="/playground_assets/ourworks/17.png" alt="Image 5" onClick={() => handleImageClick(17)} />
            <img src="/playground_assets/ourworks/18.png" alt="Image 6" onClick={() => handleImageClick(18)} />
            <img src="/playground_assets/ourworks/21.png" alt="Image 2" onClick={() => handleImageClick(21)} />
            <img src="/playground_assets/ourworks/22.png" alt="Image 3" onClick={() => handleImageClick(22)} />
            <img src="/playground_assets/ourworks/23.png" alt="Image 4" onClick={() => handleImageClick(23)} />
            <img src="/playground_assets/ourworks/24.png" alt="Image 5" onClick={() => handleImageClick(24)} />
            <img src="/playground_assets/ourworks/25.png" alt="Image 6" onClick={() => handleImageClick(25)} />
            <img src="/playground_assets/ourworks/26.png" alt="Image 7" onClick={() => handleImageClick(26)} />
            <img src="/playground_assets/ourworks/27.png" alt="Image 8" onClick={() => handleImageClick(27)} />
            <img src="/playground_assets/ourworks/28.png" alt="Image 8" onClick={() => handleImageClick(28)} />
          </div>
        </div>

        {selectedImage && (
          <div className="popup-overlay" onClick={handleClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <img
                src={selectedImage}
                alt="Enlarged"
              />
              <button className="close-btn" onClick={handleClose}>
                X
              </button>
            </div>
          </div>
        )}


      </section>
      <Wall />
      <Footer />
    </>
  )
}

export default blog