import React, { useState, useEffect } from "react";
import EmptyList from "../../components/common/EmptyList";
import BlogList from "../../components/Home/BlogList";
import Header from "../../components/Home/Header";
import SearchBar from "../../components/Home/SearchBar";
import { blogList } from "../../config/data";
import { request } from "../../../../Api";

const Home = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState(null);

  const getData = async () => {
    const promise = await request("blog/active", {}, "get");

    if (promise.error) {
      setStatus(false);
    } else {
      setBlogs(promise.response);
      //console.log(promise.response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  // Search for blog by category
  const handleSearchResults = () => {
    const allBlogs = blogList;
    const filteredBlogs = allBlogs.filter((blog) =>
      blog.category.toLowerCase().includes(searchKey.toLowerCase().trim())
    );
    setBlogs(filteredBlogs);
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setBlogs(blogList);
    setSearchKey("");
  };

  return (
    <div>
      {/* Page Header */}
      <Header />

      {/* Search Bar */}
      {/* <SearchBar
        value={searchKey}
        clearSearch={handleClearSearch}
        formSubmit={handleSearchBar}
        handleSearchKey={(e) => setSearchKey(e.target.value)}
      /> */}

      {/* Blog List & Empty View */}
      {!blogs.length ? (
        <div className="emptyList-wrap">
          {status === false ? "Blogs not found" : <EmptyList />}
        </div>
      ) : (
        <BlogList blogs={blogs} />
      )}
    </div>
  );
};

export default Home;
