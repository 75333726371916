import axios from "axios";

export const request = async (name, data, method) => {
  const apiUrl = `${process.env.REACT_APP_URL}api/${name}`;
  let returnData = { response: null, error: null };
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  await axios[method](apiUrl, data, config)
    .then((res) => {
      res.data.succeeded === true
        ? (returnData.response = res.data.ResponseBody)
        : (returnData.error = "Something went wrong.");
    })
    .catch((err) => {
      returnData.error = err;
    });
  return returnData;
};
