import React from 'react'
import RopeCard from './ropeCard'
import PropTypes from 'prop-types'


import './rope2.css'

const Rope2 = (props) => {


  const image1 = props.i1;
  const image2 = props.i2;
  const image3 = props.i3;

  const link1 = props.l1;
  const link2 = props.l2;
  const link3 = props.l3;

  const appname1 = props.a1;
  const appname2 = props.a2;
  const appname3 = props.a3;


  return (
    <div className="rope-container">
      <div className="rope-container1">
        <RopeCard perk='rotateLeft' image={image1} link={link1} app={appname1}/>
        <RopeCard perk='littleDown hide768' image={image2} link={link2} app={appname2}/>
        <RopeCard perk='rotateRight hide768' image={image3} link={link3} app={appname3}/>
      </div>
      {/* <div className="rope-container5">
        <RopeCard perk='show768 hideMax' image={image2} link={link2}/>
      </div>
      <div className="rope-container7">
        <RopeCard perk='show768 hideMax' image={image3} link={link3}/>
      </div> */}
    </div>
  )
}

export default Rope2

Rope2.defaultProps = {
  i1: 'https://plus.unsplash.com/premium_photo-1663852297825-da544188d6e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
  i2: 'https://images.unsplash.com/photo-1570654621852-9dd25b76b38d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwyMXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
  i3: 'https://images.unsplash.com/photo-1679772692624-ecadc7bae4e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyM3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
  l1:'',
  l2:'',
  l3:'',
  a1:'App Test1',
  a2:'App Test2',
  a3:'App Test3',
}

Rope2.propTypes = {
  i1: PropTypes.string,
  i2: PropTypes.string,
  i3: PropTypes.string,
  l1: PropTypes.string,
  l2: PropTypes.string,
  l3: PropTypes.string,
  a1:'',
  a2:'',
  a3:'',  
}