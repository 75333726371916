import React from 'react'

import PropTypes from 'prop-types'

import './com.css'

const Com = (props) => {
  return (
    <div className={`com-container ${props.rootClassName} `}>
      <img src={props.image_src} alt={props.image_alt} className="com-image" />
    </div>
  )
}

Com.defaultProps = {
  image_src:
    'https://images.unsplash.com/photo-1675573789887-5c0756764df4?ixid=Mnw5MTMyMXwwfDF8YWxsfDR8fHx8fHwyfHwxNjgwMTg2ODAw&ixlib=rb-4.0.3&w=200',
  image_alt: 'image',
  rootClassName: '',
}

Com.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Com
