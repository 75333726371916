import React from "react";
import { Link } from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";
import moment from "moment";
import parse from "html-react-parser";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const BlogItem = ({ blog }) => {
  return (
    <>
      <Helmet>
        {/* General meta tags for the site */}
        <title>Blog - Latest News & Insights | WM Gaming Studioz</title>
        <meta
          name="keywords"
          content="Game Development Blog, Game Design Tips, Gaming Industry News, Game Development Trends, Game Development Insights, Gaming Blog, Game Programming Tutorials, Game Development Guides, Mobile Game Development Tips, Game Development Best Practices, Video Game Development Blog, Gaming Technology News, Game Dev Trends, Indie Game Development Blog, Game Developer Resources"
        />
        <meta
          name="description"
          content="Explore our gaming development blog for valuable guides, tutorials, and expert opinions on the latest trends, tools, and techniques in game development."
        />
        <meta name="og:site_name" content="Webmobril Gaming Studioz" />
        <meta name="og:region" content="IN" />
        <meta name="og:country-name" content="INDIA" />
        <meta name="language" content="english" />

        {/* Blog-specific Open Graph meta tags */}
        <meta property="og:title" content={blog && blog.title} />
        <meta property="og:description" content={blog && blog.meta_description} />
        <meta property="og:image" content={blog && blog.image} />
        <meta property="og:url" content={blog && `https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`} />
        <meta property="og:type" content="article" />
        <meta property="og:author" content={blog && blog.author && blog.author.name} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={blog && blog.author && blog.author.name} />
        {/* Add Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${blog.title}",
            "alternativeHeadline": "${blog.meta_description}",
            "image": "${blog.image}",
            "author": {
              "@type": "Person",
              "name": "${blog.author.name}"
            },
            "editor": {
              "@type": "Person",
              "name": "Webmobril Team"
            },
            "datePublished": "${blog.createdAt}",
            "dateModified": "${blog.createdAt}",
            "publisher": {
              "@type": "Organization",
              "name": "Webmobril Gaming Studioz",
              "logo": {
                "@type": "ImageObject",
                "url": "https://wmgamingstudioz.com/playground_assets/edited logo with text 509 x 144.png"
              }
            },
            "mainEntityOfPage": "https://wmgamingstudioz.com/blog/${blog._id}/${blog.title.replace(/\s+/g, '-')}",
            "description": "${blog.meta_description}",
            "articleBody": "${blog.content.slice(0, 200)}",
            "keywords": "${blog.meta_keyword}"
          }
          `}
        </script>
      </Helmet>
      <div className="blogItem-wrap">
        <Link
          className="blogItem-link"
          to={{
            pathname: `/${blog._id}/${blog.title.replace(/\s+/g, '-')}`
          }}
        >
          <h3 style={{ height: "100px" }}>{blog.title}</h3>
        </Link>


        {/* <p>{blog.content.length>100?parse(blog.content.slice(0, 100)+' ...'):parse(blog.content)}</p> */}
        <div style={{ paddingTop: "15px" }}>
          <FacebookShareButton
            // Replace spaces with hyphens in the title for URL
            url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
            quote={"Dummy text!"}
            hashtag="#muo"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          &nbsp;&nbsp;&nbsp;
          <TwitterShareButton
            // Replace spaces with hyphens in the title for URL
            url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
            quote={"Dummy text!"}
            hashtag="#muo"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          &nbsp;&nbsp;&nbsp;
          <LinkedinShareButton
            // Replace spaces with hyphens in the title for URL
            url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
            quote={"Dummy text!"}
            hashtag="#muo"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <footer>
          <div className="blogItem-author">
            <img src="/assets/images/author.jpg" alt="avatar" />
            <div>
              <h6 style={{ fontStyle: "italic" }}>{blog.author.name}</h6>
              <p>{moment(blog.createdAt).format("D MMM YYYY")}</p>
            </div>
          </div>
          <Link className="blogItem-link" to={`/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}>
            ➝
          </Link>
        </footer>
      </div >
    </>
  );
};

export default BlogItem;
