import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import Blog from './blog/Index'
import "./blog.css"
// import { Helmet } from 'react-helmet'

const BlogList = () => {

  return (
    <>
      {/* <Helmet>
        <title>
        Blog - Latest News & Insights | WM Gaming Studioz
        </title>
        <meta
          name="keywords"
          content="Game Development Blog, Game Design Tips, Gaming Industry News, Game Development Trends, Game Development Insights, Gaming Blog, Game Programming Tutorials, Game Development Guides, Mobile Game Development Tips, Game Development Best Practices, Video Game Development Blog, Gaming Technology News, Game Dev Trends, Indie Game Development Blog, Game Developer Resources"
        />
        <meta
          name="description"
          content="Explore our gaming development blog for valuable guides, tutorials, and expert opinions on the latest trends, tools, and techniques in game development."
        />
        <meta name="og:site_name" content="Webmobril Gaming Studioz" />
        <meta name="og:region" content="IN" />
        <meta name="og:country-name" content="INDIA" />
        <meta name="language" content="english" />
      </Helmet> */}
      <Header rootClassName="header1-root-class-name" />
      <div className='showcase'>
        <div className='curtain'>
          <Blog />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BlogList