import React, { useState, useRef } from "react";
//import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./header.css";
import "./MobileNav/mobileNav.css";
//import { Result } from "postcss";
import { request } from "../Api";
import FormHooks from "../Hooks/FormHooks";

const Header = (props) => {
  const [
    valuesObj,
    InputChange,
    OnSubmit,
    setDefaultValue,
    InputError,
    DefaultError,
  ] = FormHooks({
    user_name: {
      rule: "required|alpha|multiSpaces|min:3",
      field: "Name",
      defaultValue: "",
    },
    // subject: {
    //   rule: "required|multiSpaces",
    //   field: "subject",
    //   defaultValue: "",
    // },
    message: {
      rule: "required|multiSpaces|min:3",
      field: "Message",
      defaultValue: "",
    },
    user_email: {
      rule: "required|email",
      field: "Email",
      defaultValue: "",
    },
  });
  //const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoader(true);
    const validationFlag = await OnSubmit();

    if (validationFlag) {
      let sendData = {
        name: valuesObj.user_name,
        email: valuesObj.user_email,
        message: valuesObj.message
      };
      const promise = await request("contact-us/add", sendData, "post");

      if (promise.error) {
        setLoader(false);
        //console.log("error");
      } else {
        setLoader(false);
        setStatus(true);
        setDefaultValue([
          {
            user_name: "",
            // subject: "",
            message: "",
            user_email: "",
          },
        ]);
        DefaultError([
          {
            user_name: "",
            // subject: "",
            message: "",
            user_email: "",
          },
        ]);
        //console.log(promise.response);
      }
    } else {
      //console.log("error");
      setLoader(false);
    }
    // emailjs
    //   .sendForm(
    //     "service_8dxk3gi",
    //     "template_q58xc0s",
    //     form.current,
    //     "mYc8drucW9KkaNWgY"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    // e.target.reset();
  };

  const [show_contact_menu, setShow_contact_menu] = useState(false);
  const [show_mobileNav, setShow_mobileNav] = useState(false);
  const [active, setActive] = useState(false);
  const [show_drop1, setShow_drop1] = useState(false);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(false);

  const trueToggler = () => {
    setShow_mobileNav(true);
    setActive(true);
  };

  const falseToggler = () => {
    setShow_mobileNav(false);
    setActive(false);
  };

  const contactToggler = () => {
    setShow_contact_menu(true);
    setShow_mobileNav(false);
  };
  return (
    <>
      <div className={`header-container ${props.rootClassName} `}>
        <div className="header-container1">
          <Link
            onClick={() => {
              window.scroll(0, 0);
            }}
            to="/"
            className="header-navlink"
          >
            <img
              alt={props.image_alt}
              src={props.WMLogo}
              className="header-image FadeInUp"
            />
          </Link>
          <div className="header-nav-container dragonFont">
            <div className="header-nav FadeInUp">
              {/* <Link to="/" className='header-navlink02 HeaderOnHoverTextColorful cursor_pointer'>
                {props.text100}
              </Link> */}
              <div
                className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                onMouseEnter={() => setShow_drop1(true)}
              >
                Services
              </div>
              {show_drop1 && (
                <div className="doh" onMouseLeave={() => setShow_drop1(false)}>
                  <Link
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                    to="/game-development"
                  >
                    Game Developemnt
                  </Link>
                  <Link
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                    to="/game-art"
                  >
                    Game Art
                  </Link>
                  <Link
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                    to="/outstaffing"
                  >
                    Outstaffing
                  </Link>
                  <Link
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                    to="/virtural-game"
                  >
                    Virtural Game
                  </Link>
                </div>
              )}
              <Link
                onClick={() => {
                  window.scroll(0, 0);
                }}
                className="header-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                to="/ourwork"
              >
                Our Work
              </Link>
              {/* <Link className='header-navlink05 HeaderOnHoverTextColorful cursor_pointer' to="/carrer">Carrer</Link> */}

              <Link
                onClick={() => {
                  window.scroll(0, 0);
                }}
                to="/about-us"
                className="header-navlink01 HeaderOnHoverTextColorful cursor_pointer"
              >
                {props.text}
              </Link>
              <Link
                to="/blog"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text6}
              </Link>
                {/* <Link
                to="/privacy-policy"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.textP}
              </Link> */}
              <span
                onClick={() => setShow_contact_menu(true)}
                className="header-text HeaderOnHoverTextColorful cursor_pointer"
              >
                {props.text5}
              </span>
            </div>
            <div className="header-menu-icon" onClick={trueToggler}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                id="Outline"
                viewBox="0 0 24 24"
                width="100%"
                height="100%"
              >
                <rect y="11" width="20" height="2" rx="1" />
                <rect y="4" width="20" height="2" rx="1" />
                <rect y="18" width="20" height="2" rx="1" />
              </svg>
            </div>
          </div>
          <div className="dragonFont2">
            <a
              href="/webverse/index.html"
              target="_blank"
              className="webverse-button"
            >
              WEBVERSE
            </a>
          </div>
        </div>
        {show_contact_menu && (
          <div className="header-container2 FadeInUp">
            <svg
              fill="white"
              viewBox="0 0 1024 1024"
              onClick={() => setShow_contact_menu(false)}
              className="header-close-icon cursor_pointer"
            >
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                className=""
              ></path>
            </svg>
            <div className="form-div">
              {status && <div className="texter">We appreciate you contacting us. One of our colleagues will get back in touch with you soon.</div>}
              <form className="form-control">
                <input
                  className="former"
                  id="name-form"
                  type="text"
                  placeholder="Your Name"
                  name="user_name"
                  value={valuesObj.user_name ? valuesObj.user_name : ""}
                  onChange={InputChange}
                />
                {InputError.user_name && InputError.user_name.length > 0 ? (
                  <div className="error">
                    <em>{InputError.user_name}</em>
                  </div>
                ) : null}
                <input
                  className="former"
                  id="email-form"
                  type="text"
                  placeholder="Your Email"
                  name="user_email"
                  value={valuesObj.user_email ? valuesObj.user_email : ""}
                  onChange={InputChange}
                />
                {InputError.user_email && InputError.user_email.length > 0 ? (
                  <div className="error">
                    <em>{InputError.user_email}</em>
                  </div>
                ) : null}
                {/* <input
                  className="former"
                  id="subject-form"
                  type="text"
                  placeholder="subject"
                  name="subject"
                  value={valuesObj.subject ? valuesObj.subject : ""}
                  onChange={InputChange}
                />
                {InputError.subject && InputError.subject.length > 0 ? (
                  <div className="error">
                    <em>{InputError.subject}</em>
                  </div>
                ) : null} */}
                <textarea
                  className="former"
                  id="textarea-form"
                  placeholder="Message"
                  cols="30"
                  rows="5"
                  name="message"
                  value={valuesObj.message ? valuesObj.message : ""}
                  onChange={InputChange}
                ></textarea>
                {InputError.message && InputError.message.length > 0 ? (
                  <div className="error">
                    <em>{InputError.message}</em>
                  </div>
                ) : null}
                {loader ? (
                  <button
                    className="form-btn"
                    type="submit"
                    onClick={sendEmail}
                  >
                    <span className="texter">Loading...</span>
                  </button>
                ) : (
                  <button
                    className="form-btn"
                    type="submit"
                    onClick={sendEmail}
                  >
                    <span className="texter">Send</span>
                  </button>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      {show_mobileNav && (
        <div
          className={
            active
              ? "mobile_navigation_menu fadeInRight"
              : "mobile_navigation_menu fadeInLeft"
          }
        >
          <div className="close_svg_wrapper" onClick={falseToggler}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#fff"
                d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
              />
            </svg>
          </div>
          <div className="actual_nav_mob">
            <div className="mob_nav_logo">
              {/* <img src='/playground_assets/logo small.png' alt='mobilenavigationlogo' width="150px"/> */}
            </div>
            <div className="mober-naver">
              {/* <Link
              to="/"
              className="header-mob-navlink02 HeaderOnHoverTextColorful cursor_pointer"
            >
              {props.text100}
            </Link> */}
              <Link
                to="/game-development"
                className="header-mob-navlink02 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text1}
              </Link>
              <Link
                to="/game-art"
                className="header-mob-navlink03 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text2}
              </Link>
              <Link
                to="/outstaffing"
                className="header-mob-navlink04 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text3}
              </Link>
              <Link
                to="/virtural-game"
                className="header-mob-navlink05 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text4}
              </Link>
              <Link
                to="/ourwork"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Our Work
              </Link>
              {/* <Link
              to="/carrer"
              className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
            >
              Carrer
            </Link> */}
              <Link
                to="/about-us"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text}
              </Link>
              <Link
                to="/blog"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.text6}
              </Link> 
              {/* <Link
                to="/privacy-policy"
                className="header-mob-navlink01 HeaderOnHoverTextColorful cursor_pointer"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                {props.textP}
              </Link> */}

              <span
                onClick={contactToggler}
                className="header-mob-text HeaderOnHoverTextColorful cursor_pointer"
              >
                {props.text5}
              </span>
            </div>
            {/* <div className='mob_nav_social'>
            <div className='socialIcon'><a href='#'>LinkedIn</a></div>
            <div className='socialIcon'><a href='#'>Instagram</a></div>
            <div className='socialIcon'><a href='#'>Facebook</a></div>
          </div> */}
          </div>
        </div>
      )}
    </>
  );
};

Header.defaultProps = {
  WMLogo: "/playground_assets/edited%20logo%20with%20text%20509%20x%20144.png",
  text2: "Game Art",
  text4: "Virtual Game",
  image_alt: "HeaderLogo",
  text5: "Contact Us",
  text: "About Us",
  textP: "Privacy-Policy",
  text1: "Game Development",
  rootClassName: "",
  text3: "Outstaffing",
  text100: "Home",
  text6: "Blog",
};

Header.propTypes = {
  WMLogo: PropTypes.string,
  text2: PropTypes.string,
  text4: PropTypes.string,
  image_alt: PropTypes.string,
  text5: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  text3: PropTypes.string,
  text100: PropTypes.string,
  text6: PropTypes.string,
  textP: PropTypes.string,
};

export default Header;
