import React, { useEffect, useState } from "react";
import { blogList } from "../../config/data";
import Chip from "../../components/common/Chip";
import EmptyList from "../../components/common/EmptyListBlog";
import "./styles.css";
import { Link } from "react-router-dom";
import { request } from "../../../../Api";
import { Helmet } from "react-helmet";
import Comments from "./Comments";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import moment from "moment";
import parse from "html-react-parser";

const Blog = ({ id }) => {
  const [blog, setBlog] = useState(null);
  const [status, setStatus] = useState(null);

  const getData = async () => {
    const promise = await request("blog/detail", { id }, "post");

    if (promise.error) {
      setStatus(false);
    } else {
      setBlog(promise.response);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <>
      {blog ? (
        <>
          <Helmet>
            <title>{blog.title} - WM Gaming Studioz Blog</title>
            <meta name="keywords" content={blog.meta_keyword} />
            <meta name="description" content={blog.meta_description} />
            <meta name="og:site_name" content="Webmobril Gaming Studioz" />
            <meta name="og:region" content="IN" />
            <meta name="og:country-name" content="INDIA" />
            <meta name="language" content="english" />
            <meta property="og:title" content={blog.title} />
            <meta property="og:description" content={blog.meta_description} />
            <meta property="og:image" content={blog.image} />
            <meta
              property="og:url"
              content={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
            />
            <meta property="og:type" content="article" />
            <meta property="og:author" content={blog.author.name} />
            <meta property="og:site_name" content="Webmobril Gaming Studioz" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={blog.author.name} />
            <script type="application/ld+json">
              {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${blog.title}",
            "alternativeHeadline": "${blog.meta_description}",
            "image": "${blog.image}",
            "author": {
              "@type": "Person",
              "name": "${blog.author.name}"
            },
            "editor": {
              "@type": "Person",
              "name": "Webmobril Team"
            },
            "datePublished": "${blog.createdAt}",
            "dateModified": "${blog.createdAt}",
            "publisher": {
              "@type": "Organization",
              "name": "Webmobril Gaming Studioz",
              "logo": {
                "@type": "ImageObject",
                "url": "https://wmgamingstudioz.com/playground_assets/edited logo with text 509 x 144.png"
              }
            },
            "mainEntityOfPage": "https://wmgamingstudioz.com/blog/${blog._id}/${blog.title.replace(/\s+/g, '-')}",
            "description": "${blog.meta_description}",
            "articleBody": "${blog.content.slice(0, 200)}",
            "keywords": "${blog.meta_keyword}"
          }
          `}
            </script>
          </Helmet>

          <Link className="blog-goBack" to="/">
            <span> &#8592;</span> <span>Go Back</span>
          </Link>

          <div className="blog-wrap">
            <header>
              <p className="blog-date">
                Published {moment(blog.createdAt).format("D MMM YYYY")}
              </p>
              <h2>{blog.title}</h2>
              <div className="blog-subCategory" style={{ marginBottom: "15px" }}>
                <Chip label={blog.category.name} />
              </div>
            </header>

            <img
              className="blogItem-cover"
              src={`${process.env.REACT_APP_URL}${blog.image}`}
              alt="cover"
            />

            <div className="ql-editor" style={{ fontSize: "16px" }}>
              {parse(blog.content)}
            </div>

            <div style={{ paddingTop: "15px" }}>
              <FacebookShareButton
                url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              &nbsp;&nbsp;&nbsp;
              <TwitterShareButton
                url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              &nbsp;&nbsp;&nbsp;
              <LinkedinShareButton
                url={`https://www.wmgamingstudioz.com/${blog._id}/${blog.title.replace(/\s+/g, '-')}`}
                quote={"Dummy text!"}
                hashtag="#muo"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>

          <div className="main">
            <Comments data={blog.comments} id={blog._id} />
          </div>
        </>
      ) : (
        <div className="blog-wrap">
          {status === false ? "Blog data not found" : <EmptyList />}
        </div>
      )}
    </>
  );
};

export default Blog;
